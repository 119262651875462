<template>
  <h1>💡</h1>
  <div v-if="!loading">
    <div class="accordion-container">
      <div class="row">
        <div class="col">
          <div class="tabs">
            <div class="tab">
              <input type="checkbox" id="chck1" />
              <label class="tab-label" for="chck1"
                ><span
                  >Auto dimming control
                  <span style="font-size: 0.9rem">{{ pauseCopy }}</span>
                </span>
              </label>
              <div class="tab-content">
                <div style="width: 89%; margin: auto">
                  <vue-slider
                    id="slider"
                    v-if="autoDimming"
                    :min="1"
                    :max="721"
                    :tooltip="'none'"
                    :marks="marks"
                    v-model="pauseMinutes"
                  />
                  <button
                    v-on:click="pauseAutoDimming(pauseMinutes)"
                    v-if="autoDimming"
                    class="button-43"
                    role="button"
                  >
                    Pause {{ minutesToDisplayString(pauseMinutes, maxMinutes) }}
                  </button>

                  <button
                    v-on:click="resumeAutoDimming"
                    v-if="!autoDimming"
                    class="button-43"
                    role="button"
                  >
                    Resume auto dimming
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <loading v-if="loading"></loading>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import Loading from "@/components/Loading";
import axios from "axios";

export default {
  name: "LightControls",
  components: { Loading, VueSlider },

  data() {
    return {
      maxMinutes: 720,
      loading: true,
      autoDimming: true,
      pauseMinutes: 60,
      pausedUntil: null,
      sleepMs: 3500,
    };
  },

  computed: {
    marks: function () {
      let marks = {};
      marks[this.maxMinutes.toString()] = "Indefinitely";
      marks["1"] = "";
      return marks;
    },
    pauseCopy: function () {
      if (this.autoDimming || !this.pausedUntil) {
        return "";
      }

      let displayDate = this.unixTimeStampToDateTime(this.pausedUntil);

      var today = new Date();
      var isToday = today.toDateString() === displayDate.toDateString();

      if (isToday) {
        displayDate = displayDate.toLocaleTimeString().slice(0, -3);
      }

      if (!isToday) {
        displayDate = displayDate.toLocaleString();
      }

      return `(paused until ${displayDate})`;
    },
  },

  beforeMount() {
    const axios = require("axios");

    axios
      .get("https://lightsettings.sgb.workers.dev/")
      .then((response) => {
        if (!response.data["autoDimmingPausedUntil"]) {
          return;
        }

        // handle success
        this.pausedUntil = response.data["autoDimmingPausedUntil"];

        console.log(this.pausedUntil);

        // autodimming isn't paused if pausedUntil is in the past:
        this.autoDimming =
          new Date() > this.unixTimeStampToDateTime(this.pausedUntil);
      })
      .catch(() => {
        alert("error");
      })
      .then(() => {
        // always executed
        this.loading = false;
      });
  },

  methods: {
    async sleep(ms) {
      await new Promise((r) => setTimeout(r, ms));
    },

    unixTimeStampToDateTime(timeStampInt) {
      return new Date(parseInt(timeStampInt));
    },

    async pauseAutoDimming(minutes) {
      this.loading = true;
      if (!this.autoDimming) {
        return;
      }

      // lazy way of getting around eventual consistency:
      await this.sleep(this.sleepMs);

      let newPausedUntil = +new Date(new Date().getTime() + minutes * 60000);

      axios
        .post(`https://lightsettings.sgb.workers.dev/`, {
          autoDimmingPausedUntil: newPausedUntil,
        })
        .then(() => {
          this.autoDimming = false;
        })
        .catch(() => {
          alert("error");
        })
        .then(() => {
          // lazy way of getting around eventual consistency:
          // always executed
          this.pausedUntil = newPausedUntil;
          this.loading = false;
        });
    },

    async resumeAutoDimming() {
      this.loading = true;
      // lazy way of getting around eventual consistency:
      await this.sleep(this.sleepMs);

      axios
        .post(
          `https://lightsettings.sgb.workers.dev/`,
          JSON.stringify({
            autoDimmingPausedUntil: 1,
          })
        )
        .then(() => {
          this.autoDimming = true;
        })
        .catch(() => {
          alert("error");
        })
        .then(() => {
          // always executed
          this.loading = false;
        });
    },

    minutesToDisplayString: (mins, maxMinutes) => {
      if (mins > maxMinutes) {
        return "Indefinitely";
      }

      let plural = mins > 1 ? "s" : "";

      if (mins < 60 || mins === 60) {
        return `for ${mins} minute${plural}`;
      }
      let h = Math.floor(mins / 60);
      let m = mins % 60;
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      return `for ${h} hours, ${m} minute${plural}`;
    },
  },
};

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function () {
    this.classList.toggle("active");
    var panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  });
}
</script>

<style scoped>
#slider {
  margin-bottom: 40px;
}

/* CSS */
.button-43 {
  background-image: linear-gradient(-180deg, #37aee2 0%, #1e96c8 100%);
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 1rem 1.75rem;
  text-decoration: none;
  width: 100%;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-43:hover {
  background-image: linear-gradient(-180deg, #1d95c9 0%, #17759c 100%);
}

@media (min-width: 768px) {
  .button-43 {
    padding: 1rem 2rem;
  }
}

.button-78 {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01, sans-serif;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}

@media (min-width: 768px) {
  .button-78 {
    padding: 19px 32px;
  }
}

.button-78:before,
.button-78:after {
  border-radius: 80px;
}

.button-78:before {
  background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.button-78:after {
  background-color: initial;
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.button-78:hover:not(:disabled):before {
  background: linear-gradient(
    92.83deg,
    rgb(255, 116, 38) 0%,
    rgb(249, 58, 19) 100%
  );
}

.button-78:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
  opacity: 0;
}

.button-78:active:not(:disabled) {
  color: #ccc;
}

.button-78:active:not(:disabled):before {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
}

.button-78:active:not(:disabled):after {
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.button-78:disabled {
  cursor: default;
  opacity: 0.24;
}

.card {
  margin: auto;
  width: 90%;
  background-color: lightgrey;
  padding: 5%;
  height: 40%;
  border-radius: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
  margin: auto auto 5%;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2ab934;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on,
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*--------- accord --------*/

body {
  color: #2c3e50;
  background: #ecf0f1;
  padding: 0 1em 1em;
}

h1 {
  color: #2c3e50;
  margin: 0;
  line-height: 2;
  text-align: center;
}

h2 {
  color: #2c3e50;
  margin: 0 0 0.5em;
  font-weight: normal;
  margin-bottom: 10%;
}

.accordion-container input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.row {
  display: flex;
}

.row .col {
  flex: 1;
}

/*.row .col:last-child {*/
/*  margin-left: 1em;*/
/*}*/

/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #2c3e50;
  font-weight: bold;
  cursor: pointer;
  /* Icon */
}

.tab-label:hover {
  background: #1a252f;
}

.tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}

.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}

.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}

.tab-close:hover {
  background: #1a252f;
}

input:checked + .tab-label {
  background: #1a252f;
}

input:checked + .tab-label::after {
  transform: rotate(90deg);
}

input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

/*-----------range input-------------*/

p,
label {
  font: 1rem "Fira Sans", sans-serif;
}

input {
  margin: 0.4rem;
}
</style>
